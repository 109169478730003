<template>
  <b-button
    :class="buttonClasses"
    :variant="buttonVariant"
    :disabled="disabled"
    @click="changeStatus"
  >
    {{ buttonText }}
  </b-button>
</template>

<script>

export default {
  name: 'WorkflowButtonSingle',
  props: {
    buttonStyle: String,
    buttonContent: String,
    disabled: Boolean,
    buttonsInfo: Object,
    action: Function,
  },
  computed: {
    buttonClasses() {
      const baseClasses = 'ms-2 btn-sm';
      if (this.buttonClass) return `${baseClasses} ${this.buttonClass}`;
      return baseClasses;
    },
    buttonText() {
      // console.log('this.buttonContent', this.buttonContent, this.buttonsInfo[this.buttonContent]);
      const a = this.buttonsInfo[this.buttonContent];
      if (a?.label) {
        return a.label;
      }
      return `label ${a} mancante`;
    },
    buttonVariant() {
      const a = this.buttonsInfo[this.buttonContent];
      if (a?.theme) {
        return a.theme;
      }
      return 'primary';
    },
  },
  methods: {
    changeStatus() {
      this.action(this.buttonContent);
    },
  },
};
</script>
